import { customToast, Variants } from 'carrier-fe';
import { CompanyStorePayload } from '../../../types/companies';
import { CompanyTypes, SYSTEM_TYPES } from '../../../Constants';
import ViessmannGas from 'carrier-fe/src/images/viessmann_gas.jpg';
import ViessmannHeatpumps from 'carrier-fe/src/images/viessmann_heatpumps.jpg';
import ViessmannOther from 'carrier-fe/src/images/viessmann_other.png';
import ViessmannSolar from 'carrier-fe/src/images/viessmann_solar.jpg';

export const defaultData: CompanyStorePayload = {
   name: '',
   type: '',
   fgas_refcom_standard_number: '',
   refcom_elite_number: '',
   account_number: '',
   sap_number: '',
   dispatch_note_field_label: '',
   dispatch_note_required: false,
   distributor_claim_type: '',
   distributor_claim_inbox_email: '',
   gas_boiler_systems_enabled: false,
   heat_pump_systems_enabled: false,
   solar_systems_enabled: false,
   other_systems_enabled: false,
   gas_safe_confirmed: false,
   gas_safe_number: '',
   gas_safe_licence_card_number: '',
   user_gas_safe_confirmed: false,
   customer_email_enabled: true,
   system_access_enabled: false,
   address_id: '',
   address_line_1: '',
   address_line_2: '',
   address_line_3: '',
   town_city: '',
   state_county: '',
   postcode_zipcode: '',
   local_authority_code: '',
   sub_country_name: '',
   country_code_iso_3: '',
   distributor_ids: [],
   username: '',
   role_id: '',
   email: '',
   first_name: '',
   last_name: '',
   landline_phone_prefix: '',
   landline_phone_number: '',
   mobile_phone_prefix: '',
   mobile_phone_number: '',
   user_address_id: '',
   user_address_line_1: '',
   user_address_line_2: '',
   user_address_line_3: '',
   user_town_city: '',
   user_state_county: '',
   user_postcode_zipcode: '',
   user_local_authority_code: '',
   user_sub_country_name: '',
   user_country_code_iso_3: '',
   mcs_number: '',
};

const companyTypes = [
   { value: CompanyTypes.DISTRIBUTOR, label: 'Distributor' },
   { value: CompanyTypes.INSTALLER, label: 'Installer' },
]

export const initialSelectsState = {
   roles: [],
   companies: [],
   distributor_claim_types: [],
   company_types: companyTypes,
};

export const selectsReducer = (state: typeof initialSelectsState, action: any) => {
   switch (action.type) {
      case 'SET_ROLES':
         return { ...state, roles: action.payload };
      case 'SET_COMPANIES':
         return { ...state, companies: action.payload };
      case 'SET_DISTRIBUTOR_CLAIM_TYPES':
         return { ...state, distributor_claim_types: action.payload };
      default:
         return state;
   }
};

export const handleError = (
   error: any,
   defaultMessage: string = 'An error occurred. Please refresh the page to try again.'
) => {
   const message = error?.response?.data?.message || defaultMessage;
   customToast({
      title: message,
      variant: Variants.Danger,
   });
};
