import {
	API_ENDPOINTS,
	DataTable,
	formatDate,
	FormInput,
	Icon,
	Icons,
	PageLayout,
	route,
	useLangContext,
	replaceKeyWithValue,
	StringHelpers,
	useAuthorisationContext,
	getThemeFromUrl,
	Themes,
	useDisabledContext,
	Variants,
} from 'carrier-fe';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { UserType } from '../../types/user';

function UserList() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { fields, crud, models } = useLangContext();
	const { hasPolicyAccess } = useAuthorisationContext();
	const { disabled } = useDisabledContext();
	const { hasRoleAccess } = useAuthorisationContext();
	const [selectedItems, setSelectedItems] = useState(0);

	const title = useMemo(
		() =>
			StringHelpers.title(
				replaceKeyWithValue(
					crud?.pages?.index.user || ':model List ',
					'model',
					crud?.models?.user || 'User'
				)
			),
		[crud, models]
	);

	const handleOptionSelect = (action: any, data: UserType) => {
		const { id = '' } = data;

		const actions: Record<string, () => void> = {
			view: () => navigate(`/user/${id}`),
		};

		if (typeof action === 'string' && actions[action]) {
			actions[action]();
		}
	};

	const items = useMemo(() => {
		const i = [];

		if (hasPolicyAccess({ name: 'user', method: 'create' })) {
			i.push({
				label: StringHelpers.title(
					crud.buttons.create.default || 'Create'
				),
				disabled: disabled,
				href: '/user/create',
				icon: <Icon icon={Icons.ADD} />,
			});
		}

		if (
			getThemeFromUrl() === Themes.Viessmann &&
			hasPolicyAccess({ name: 'user', method: 'create' })
		) {
			i.push({
				label: StringHelpers.title(
					crud.buttons.viessmann_import?.default ||
						'Import Viessmann User Export'
				),
				disabled: disabled,
				onclick: () => alert('Not Implemented'),
				icon: <Icon icon={Icons.OPEN} />,
			});
		}

		i.push({
			label: StringHelpers.title(
				crud.buttons.export?.default || 'Export'
			),
			disabled: selectedItems < 1 || disabled,
			onclick: () => alert('Not Implemented'),
			icon: <Icon icon={Icons.DOWNLOAD} />,
		});

		return i;
	}, []);

	const colMapping = (data: UserType) => {
		const cols: any = [
			{
				id: 'name',
				label: StringHelpers.title(fields?.name || 'Name'),
				value: StringHelpers.title(data.name || '-'),
				sortable: true,
			},
			{
				id: 'company_name',
				label: StringHelpers.title(fields?.company || 'Company'),
				value: StringHelpers.title(data.company_name || '-'),
				sortable: true,
				visibleCondition: () =>
					hasRoleAccess(['super_admin']) ||
					hasRoleAccess(['platform_manager']) ||
					hasRoleAccess(['distribution_manager']),
			},
			{
				id: 'email',
				label: StringHelpers.title(fields?.email || 'Email'),
				value: data.email || '-',
				sortable: true,
			},
			{
				id: 'role_name',
				label: StringHelpers.title(fields?.roles || 'Role'),
				value: StringHelpers.title(data.role_name_display || '-'),
				sortable: true,
				visibleCondition: () =>
					hasRoleAccess('super_admin') ||
					hasRoleAccess('distribution_manager') ||
					hasRoleAccess('platform_manager') ||
					hasRoleAccess('company_manager') ||
					hasRoleAccess('customer_manager'),
			},
			{
				id: 'created_at',
				label: StringHelpers.title(fields?.created || 'Created'),
				value: formatDate(data.created_at) || '-',
				sortable: true,
			},
			{
				id: 'deleted_at',
				label: StringHelpers.title(fields?.archived || 'Archived'),
				value: formatDate(data.deleted_at) || '-',
				sortable: true,
				visibleCondition: () => !!searchParams.get('filter[trashed]'),
			},
			{
				id: 'actions',
				label: StringHelpers.title(fields?.actions || 'Actions'),
				value: data.permissions.view ? (
					<FormInput
						type="select"
						label={StringHelpers.title(fields?.actions || 'Action')}
						placeholder={StringHelpers.title(
							fields?.select || 'Select'
						)}
						name="actions"
						value=""
						disabled={disabled}
						onChange={(value) => {
							if (!!value && typeof value === 'string') {
								handleOptionSelect(value, data);
							}
						}}
						options={[
							{
								label: StringHelpers.title(
									crud?.view || 'View'
								),
								value: 'view',
							},
						]}
						noMargin
					/>
				) : null,
			},
		];

		return cols;
	};

	return (
		<PageLayout title={title || 'User Management'}>
			<DataTable<UserType>
				routeEndpoint={route(API_ENDPOINTS.ADMIN.USER.INDEX)}
				colMapping={colMapping}
				items={items}
				incrementPaginationButtons
				canViewArchived
				onSelect={(e) => setSelectedItems(e.length)}
				selectable
				variant={Variants.Info}
			/>
		</PageLayout>
	);
}

export default UserList;
