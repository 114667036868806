import { 
    API_ENDPOINTS, 
    DataTable, 
    FormInput, 
    PageLayout, 
    replaceKeyWithValue, 
    route, 
    StringHelpers, 
    useLangContext,
	Variants
} from "carrier-fe";
import { useMemo } from "react";
import { EmailType } from "../../types/email";
import { useNavigate } from "react-router-dom";

function EmailTemplateList() {
    const navigate = useNavigate();
    const { fields, crud, models } = useLangContext();

    const title = useMemo(
		() =>
			StringHelpers.title(
				replaceKeyWithValue(
					crud?.pages?.index.title || ':model List ',
					'model',
					crud?.models?.email_template || 'Email Template'
				)
			),
		[crud, models]
	);

    const handleOptionSelect = (action: any, data: EmailType) => {
            const { id = '' } = data;
    
            const actions: Record<string, () => void> = {
                edit: () => navigate(`/email-templates/${id}`),
            };
    
            if (typeof action === 'string' && actions[action]) {
                actions[action]();
            }
        };

    const colMapping = (data: EmailType) => {
        const cols: any = [
            {
				id: 'name',
				label: StringHelpers.title(fields?.name || 'Name'),
				value: data.name,
				sortable: true,
			},
            {
				id: 'actions',
				label: StringHelpers.title(fields?.actions || 'Actions'),
				value: data.permissions.update ? (
					<FormInput
						type="select"
						label={StringHelpers.title(fields?.actions || 'Action')}
						placeholder={StringHelpers.title(
							fields?.select || 'Select'
						)}
						name="actions"
						value=""
                        onChange={(value) => {
							if (!!value && typeof value === 'string') {
								handleOptionSelect(value, data);
							}
						}}
						options={[
							{
								label: StringHelpers.title(
									crud?.select_labels?.edit || 'Edit'
								),
								value: 'edit',
							},
						]}
						noMargin
					/>
				) : null,
			},
        ];

        return cols;
    };

    return (
        <PageLayout title={title || 'Email Templates'}>
            <DataTable<EmailType>
                routeEndpoint={route(API_ENDPOINTS.ADMIN.NOTIFICATION_TEMPLATE.INDEX, { format: "flat" })}
                colMapping={colMapping}
				variant={Variants.Info}
            />
        </PageLayout>
    )
}

export default EmailTemplateList;