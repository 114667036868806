import {
	API_ENDPOINTS,
	Badge,
	DataTable,
	formatDate,
	route,
	StringHelpers,
	useLangContext,
	Variants,
} from 'carrier-fe';
import { useParams } from 'react-router-dom';

type CourseType = {
	id: string;
	name: string;
	category_name: string;
	due_at: string;
	admin_status: string;
	status_at: string;
	permissions: {
		view: boolean;
		update: boolean;
		delete: boolean;
		restore: boolean;
	};
};

function TrainingCourses() {
	const { fields, crud } = useLangContext();
	const { userId } = useParams();

	const getVariant = (status: string) => {
		switch (status) {
			case 'Completed':
				return Variants.Success;
			case 'Overdue':
				return Variants.Danger;
			case 'Outstanding':
				return Variants.Warning;
			default:
				return Variants.Primary;
		}
	};

	const colMapping = (data: CourseType) => [
		{
			id: 'name',
			label: StringHelpers.title(fields?.name || 'Name'),
			value: StringHelpers.title(data.name || '-'),
			sortable: true,
		},
		{
			id: 'category_name',
			label: StringHelpers.title(fields?.category || 'Category'),
			value: StringHelpers.title(data.category_name || '-'),
			sortable: true,
		},
		{
			id: 'due_at',
			label: StringHelpers.title(fields?.due_at || 'Due'),
			value: formatDate(data.due_at) || '-',
			sortable: true,
		},
		{
			id: 'admin_status',
			label: StringHelpers.title(fields?.status || 'Status'),
			value: data.admin_status ? (
				<Badge
					label={StringHelpers.title(data.admin_status)}
					variant={getVariant(data.admin_status)}
				/>
			) : (
				'-'
			),
			sortable: true,
		},
	];

	return (
		<DataTable<CourseType>
			title={StringHelpers.title(
				crud?.sub_titles?.training_courses || 'Training Courses'
			)}
			routeEndpoint={route(API_ENDPOINTS.ADMIN.USER.TRAINING_COURSES, {
				userId: String(userId),
			})}
			colMapping={colMapping}
			incrementPaginationButtons={true}
		/>
	);
}

export default TrainingCourses;
