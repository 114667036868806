import axios from 'axios';
import {
	API_ENDPOINTS,
	Button,
	ButtonTypes,
	Card,
	CardBody,
	customToast,
	Dropdown,
	formatDate,
	Icons,
	Modal,
	PageLayout,
	replaceKeyWithValue,
	route,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	useLangContext,
	useStore,
	Variants,
	WidthConstrainedContainer,
} from 'carrier-fe';
import React, { useEffect, useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { VenueType } from '../../../types/venue';
import { DropdownItemProps } from 'carrier-fe/lib/components/Controls/Dropdown/DropdownItem/DropdownItem';

function Venue() {
	const { venueId } = useParams();

	const { crud, fields } = useLangContext();
	const { disabled, setDisabled } = useDisabledContext();
	const navigate = useNavigate();

	const { store, setValues } = useStore<VenueType>({} as VenueType);

	const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);
	const [archiving, setArchiving] = useState<boolean>(false);

	useEffect(() => {
		fetchVenueData();
	}, [venueId]);

	useEffect(() => {
		if (archiving) {
			archive();
		}
	}, [archiving]);

	const fetchVenueData = useCallback(async () => {
		try {
			const { data } = await axios.get<{ data: VenueType }>(
				route(API_ENDPOINTS.ADMIN.TRAINING.VENUE.VIEW, {
					trainingVenue: String(venueId),
				})
			);
			setValues(data.data);
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		}
	}, [venueId]);

	const archive = async () => {
		setDisabled(true);

		try {
			let response = await axios.delete(
				route(API_ENDPOINTS.ADMIN.TRAINING.VENUE.DELETE, {
					trainingVenue: String(venueId),
				})
			);

			customToast({
				title: response?.data?.message || 'Success.',
				variant: Variants.Success,
			});
			await fetchVenueData();
		} catch (error: any) {
			customToast({
				// @ts-ignore
				title:
					error?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			setDisabled(false);
			setArchiving(false);
			setArchiveModalOpen(false);
		}
	};

	const getActions = (): DropdownItemProps[] => {
		if (!store || !store.permissions) return [];

		let actions: DropdownItemProps[] = [];

		if (store?.permissions.update) {
			actions.push({
				label: StringHelpers.title(
					crud?.buttons.edit.default || 'Update'
				),
				onClick: () => navigate(`/training/venue/${venueId}/edit`),
				variant: Variants.Dark,
				icon: Icons.EDIT,
				disabled: disabled,
			});
		}

		if (store?.permissions.delete) {
			actions.push({
				label: StringHelpers.title(
					crud?.buttons.archive.default || 'Archive'
				),
				onClick: () => setArchiveModalOpen(true),
				variant: Variants.Danger,
				icon: Icons.DELETE,
				disabled: disabled,
			});
		}

		return actions;
	};

	let title = replaceKeyWithValue(
		crud?.pages?.view.title || 'View :model',
		'model',
		crud?.models?.training_venue || 'Training Venue'
	);

	let actions = getActions();

	return (
		<PageLayout title={StringHelpers.title(title)}>
			<WidthConstrainedContainer>
				<div className="mb-4 d-flex justify-content-between align-items-center">
					<Button
						label={StringHelpers.title(
							crud?.buttons?.back?.default || 'Back'
						)}
						onClick={() => navigate('/training/venue')}
						variant={Variants.Dark}
						type={ButtonTypes.Outline}
						disabled={disabled}
					/>
					{actions.length > 0 && (
						<Dropdown
							label={StringHelpers.title(
								crud?.buttons?.actions?.default || 'Actions'
							)}
							items={getActions()}
							type={ButtonTypes.Solid}
							variant={Variants.Dark}
							disabled={disabled}
						/>
					)}
				</div>
				<Card>
					<CardBody>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.details || 'Details'
							)}
							className="mb-3"
							style={{ color: '#464C5E' }}
						/>
						<div className="row mb-4">
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.name || 'Name'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.name ?? '-'
										)}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.capacity || 'Capacity'
										)}
									</strong>
									<div>{store?.capacity ?? '-'}</div>
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xxl-4">
								<div
									className="d-flex flex-column mb-3"
									style={{ overflow: 'hidden' }}
								>
									<strong>
										{StringHelpers.title(
											fields?.map_url || 'Map URL'
										)}
									</strong>
									<div>
										{store?.map_url ? (
											<a
												href={store.map_url}
												className={
													'btn btn-link btn-primary p-0'
												}
												target={'_blank'}
											>
												{store.map_url}
											</a>
										) : (
											'-'
										)}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.enabled || 'enabled'
										)}
									</strong>
									<div>
										{!!store &&
										store.enabled &&
										store.enabled === '1'
											? 'Y'
											: 'N'}
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.created_at || 'created'
										)}
									</strong>
									<div>
										{store?.created_at
											? formatDate(store.created_at)
											: '-'}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.updated_at || 'updated'
										)}
									</strong>
									<div>
										{store?.updated_at
											? formatDate(store.updated_at)
											: '-'}
									</div>
								</div>
								{!!store?.deleted_at && (
									<div className="d-flex flex-column mb-3">
										<strong>
											{StringHelpers.title(
												fields?.archived_at ||
													'archived'
											)}
										</strong>
										<div>
											{store?.deleted_at
												? formatDate(store.deleted_at)
												: '-'}
										</div>
									</div>
								)}
							</div>
						</div>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.address || 'Address'
							)}
							className="mb-3"
							style={{ color: '#464C5E' }}
						/>
						<div className="row">
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.address_line_1 ||
												'address line 1'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.address?.address_line_1 ||
												'-'
										)}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.address_line_2 ||
												'address line 2'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.address?.address_line_2 ||
												'-'
										)}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.address_line_3 ||
												'address line 3'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.address?.address_line_3 ||
												'-'
										)}
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.town_city || 'town / city'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.address?.town_city || '-'
										)}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.state_county ||
												'state / county'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.address?.state_county || '-'
										)}
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.postcode_zipcode ||
												'postcode / zipcode'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.address?.postcode_zipcode ||
												'-'
										)}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.country_code_iso_3 ||
												'country'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.address?.country || '-'
										)}
									</div>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
			</WidthConstrainedContainer>

			<Modal
				open={archiveModalOpen}
				onClose={() => setArchiveModalOpen(false)}
				title={StringHelpers.title(
					replaceKeyWithValue(
						crud?.modals?.archive.title || 'Archive :name?',
						'name',
						crud?.models?.training_venue || 'Training Venue'
					)
				)}
				confirmText={
					archiving
						? crud?.buttons.archive.submitting || 'Archiving...'
						: crud?.buttons.archive.default || 'Archive'
				}
				closeText={crud?.buttons.cancel.default || 'Cancel'}
				onConfirm={() => setArchiving(true)}
				disabled={disabled || archiving}
				variant={Variants.Danger}
			>
				{crud?.modals?.archive.description.map(
					(item: string, key: number) => {
						return (
							<p key={key}>
								{replaceKeyWithValue(item, 'name', store?.name)}
							</p>
						);
					}
				)}
			</Modal>
		</PageLayout>
	);
}

export default Venue;
