import {
	API_ENDPOINTS,
	DataTable,
	formatDate,
	FormInput,
	Icon,
	Icons,
	ImageType,
	PageLayout,
	replaceKeyWithValue,
	route,
	StringHelpers,
	useAuthorisationContext,
	useLangContext,
	Variants,
} from 'carrier-fe';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EventType } from '../../types/event';

function EventList() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { crud, models, fields } = useLangContext();
	const { hasPolicyAccess } = useAuthorisationContext();

	const title = useMemo(
		() =>
			StringHelpers.title(
				replaceKeyWithValue(
					crud?.pages?.index.title || ':model List ',
					'model',
					crud?.models?.training_event || 'Training Event'
				)
			),
		[crud, models]
	);

	const handleOptionSelect = (action: any, data: EventType) => {
		const { id = '' } = data;

		const actions: { [key: string]: () => void } = {
			view: () => navigate(`/training/event/${id}`),
		};

		if (typeof action === 'string' && actions[action]) {
			actions[action]();
		}
	};

	const items = useMemo(() => {
		if (!hasPolicyAccess({ name: 'training_event', method: 'create' }))
			return [];

		return [
			{
				label: StringHelpers.title(
					crud.buttons.create.default || 'Create'
				),
				disabled: false,
				onClick: () => navigate('/training/event/create'),
				icon: <Icon icon={Icons.ADD} />,
			},
		];
	}, []);

	const colMapping = (data: EventType) => {
		const cols: any = [
			{
				id: 'name',
				label: StringHelpers.title(fields?.name || 'Name'),
				value: StringHelpers.title(data.name || '-'),
				sortable: true,
			},
			{
				id: 'course',
				label: StringHelpers.title(
					fields?.training_course_id || 'Course'
				),
				value: data.course || '-',
				sortable: true,
			},
			{
				id: 'event_at',
				label: StringHelpers.title(fields?.event_at || 'Date'),
				value: formatDate(data.event_at) || '-',
				sortable: true,
			},
			{
				id: 'attendee_count',
				label: StringHelpers.title(fields?.attendees || 'Attendees'),
				value: data.attendee_count || '-',
				sortable: true,
			},
			{
				id: 'completed_at',
				label: StringHelpers.title(fields?.completed_at || 'Completed'),
				value: !!data.completed_at ? 'Y' : 'N',
				sortable: true,
			},
			{
				id: 'created_at',
				label: StringHelpers.title(fields?.created || 'Created'),
				value: formatDate(data.created_at) || '-',
				sortable: true,
			},
			{
				id: 'deleted_at',
				label: StringHelpers.title(fields?.archived || 'Archived'),
				value: formatDate(data.deleted_at) || '-',
				sortable: true,
				visibleCondition: () => !!searchParams.get('filter[trashed]'),
			},
			{
				id: 'actions',
				label: StringHelpers.title(fields?.actions || 'Actions'),
				value: data.permissions.view ? (
					<FormInput
						type="select"
						label={StringHelpers.title(fields?.actions || 'Action')}
						placeholder={StringHelpers.title(
							fields?.select || 'Select'
						)}
						name="actions"
						value=""
						onChange={(value) => {
							if (!!value && typeof value === 'string') {
								handleOptionSelect(value, data);
							}
						}}
						options={[
							{
								label: StringHelpers.title(
									crud?.view || 'View'
								),
								value: 'view',
							},
						]}
						noMargin
					/>
				) : null,
			},
		];

		return cols;
	};

	return (
		<PageLayout title={title || 'Training Events'}>
			<DataTable<EventType>
				routeEndpoint={route(API_ENDPOINTS.ADMIN.TRAINING.EVENT.INDEX)}
				colMapping={colMapping}
				items={items}
				incrementPaginationButtons
				canViewArchived
				variant={Variants.Info}
			/>
		</PageLayout>
	);
}

export default EventList;
