import axios from 'axios';
import {
   API_ENDPOINTS,
   Button,
   ButtonTypes,
   Card,
   CardBody,
   CardData,
   customToast,
   DateFormats,
   Dropdown,
   formatDateFns,
   getThemeFromUrl,
   Icons,
   Modal,
   PageLayout,
   Permissions,
   replaceKeyWithValue,
   route,
   Spinner,
   StringHelpers,
   SubTitle,
   Themes,
   useAuthorisationContext,
   useDisabledContext,
   useLangContext,
   useQuery,
   Variants,
   WidthConstrainedContainer,
} from 'carrier-fe';
import { DropdownItemProps } from 'carrier-fe/lib/components/Controls/Dropdown/DropdownItem/DropdownItem';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyTypes, REG_STATUS } from '../../../Constants';
import { CompanyView } from '../../../types/companies/view';
import UsersList from './PageComponents/UsersList';

function Company() {
   const { companyId } = useParams();
   const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);
   const [archiving, setArchiving] = useState<boolean>(false);
   const navigate = useNavigate();
   const { crud, fields } = useLangContext();
   const { hasPermissionAccess } = useAuthorisationContext();
   const { disabled, setDisabled } = useDisabledContext();
   const [restoring, setRestoring] = useState<boolean>(false);
   const [restoreModalOpen, setRestoreModalOpen] = useState<boolean>(false);

   const {
      data: companyData,
      isLoading,
      error,
      refetch,
   } = useQuery<{ data: CompanyView }>(
      route(API_ENDPOINTS.ADMIN.COMPANY.VIEW, {
         companyId: String(companyId),
      }),
      {
         method: 'GET',
      }
   );

   useEffect(() => {
      if (error) {
         customToast({
            title: (error as any)?.response?.data?.message,
            variant: Variants.Danger,
         });
      }
   }, [error]);

   const archive = async () => {
      setDisabled(true);
      setArchiving(true);

      try {
         const { data } = await axios.delete(
            route(API_ENDPOINTS.ADMIN.COMPANY.DELETE, {
               companyId: String(companyId),
            })
         );
         customToast({
            title: data.message || '',
            variant: Variants.Success,
         });
         refetch();
      } catch (err: any) {
         customToast({
            title: err?.response?.data?.message || 'An error occurred. Please try again.',
            variant: Variants.Danger,
         });
      } finally {
         setDisabled(false);
         setArchiving(false);
         setArchiveModalOpen(false);
      }
   };

   const restore = async () => {
      setDisabled(true);
      setRestoring(true);

      try {
         let response = await axios.patch(
            route(API_ENDPOINTS.ADMIN.COMPANY.RESTORE, {
               companyId: String(companyId),
            })
         );
         customToast({
            title: response?.data?.message || 'Success.',
            variant: Variants.Success,
         });
         refetch();
      } catch (error: any) {
         customToast({
            title: error?.response?.data?.message || 'An error occurred. Please try again.',
            variant: Variants.Danger,
         });
      } finally {
         setDisabled(false);
         setRestoreModalOpen(false);
         setRestoring(false);
      }
   };

   const getActions = (): DropdownItemProps[] => {
      if (!companyData || !companyData?.data) return [];
      const actions: DropdownItemProps[] = [];

      const { permissions } = companyData?.data;

      if (
         hasPermissionAccess(Permissions.ADMIN_COMPANY_APPROVE_REGISTRATION) &&
         companyData?.data?.registration_status === REG_STATUS.PENDING &&
         themes.isToshiba
      ) {
         actions.push({
            label: StringHelpers.title(
               fields?.approve_registration || 'Approve registration' //ADD LANG
            ),
            onClick: () => alert('Not Implemented Yet!'),
            variant: Variants.Dark,
            icon: Icons.SUCCESS,
            disabled: disabled,
         });
      }

      if (
         hasPermissionAccess(Permissions.ADMIN_COMPANY_REJECT_REGISTRATION) &&
         companyData?.data?.registration_status === REG_STATUS.PENDING &&
         themes.isToshiba
      ) {
         actions.push({
            label: StringHelpers.title(
               fields?.reject_registration || 'Reject registration' //ADD LANG
            ),
            onClick: () => alert('Not Implemented Yet!'),
            variant: Variants.Dark,
            icon: Icons.WARNING,
            disabled: disabled,
         });
      }

      if (
         hasPermissionAccess(Permissions.ADMIN_COMPANY_APPROVE_FGAS_REFCOM) &&
         companyData?.data?.registration_status === REG_STATUS.PENDING &&
         companyData?.data?.type === 'installer' &&
         themes.isToshiba
      ) {
         actions.push({
            label: StringHelpers.title(
               fields?.approve_fgas || 'Approve FGAS' //ADD LANG
            ),
            onClick: () => alert('Not Implemented Yet!'),
            variant: Variants.Dark,
            icon: Icons.SUCCESS,
            disabled: disabled,
         });
      }

      if (
         hasPermissionAccess(Permissions.ADMIN_COMPANY_APPROVE_FGAS_REFCOM) &&
         companyData?.data?.registration_status === REG_STATUS.PENDING &&
         companyData?.data?.type === 'installer' &&
         themes.isToshiba
      ) {
         actions.push({
            label: StringHelpers.title(
               fields?.approve_refcom || 'Approve REFCOM' //ADD LANG
            ),
            onClick: () => alert('Not Implemented Yet!'),
            variant: Variants.Dark,
            disabled: disabled,
         });
      }

      if (permissions.update) {
         actions.push({
            label: StringHelpers.title(crud?.buttons.edit.default || 'Edit'),
            onClick: () => navigate(`/companies/${companyId}/edit`),
            variant: Variants.Dark,
            icon: Icons.EDIT,
            disabled: disabled,
         });
      }

      if (permissions.view && themes.isToshiba) {
         actions.push({
            label: StringHelpers.title(
               crud?.buttons.download_certificate || 'Download certificate' //ADD LANG
            ),
            onClick: () => alert('Not Implemented Yet!'),
            variant: Variants.Dark,
            icon: Icons.DOWNLOAD,
            disabled: disabled,
         });
      }

      if (permissions.delete && !companyData?.data?.deleted_at) {
         actions.push({
            label: StringHelpers.title(crud?.buttons.archive.default || 'Archive'),
            onClick: () => setArchiveModalOpen(true),
            variant: Variants.Danger,
            icon: Icons.DELETE,
            disabled: disabled,
         });
      }

      if (permissions.restore && companyData?.data?.deleted_at) {
         actions.push({
            label: StringHelpers.title(crud?.buttons.restore.default || 'Restore'),
            onClick: () => setRestoreModalOpen(true),
            variant: Variants.Dark,
            icon: Icons.CHECKBOX_ON,
            disabled: disabled,
         });
      }

      return actions;
   };

   const themes = useMemo(() => {
      return {
         isToshiba: getThemeFromUrl() === Themes.Toshiba,
         isViessmann: getThemeFromUrl() === Themes.Viessmann,
      };
   }, []);

   const booleanOptions = useMemo(() => {
      return {
         YES: crud?.options?.boolean?.find((x: any) => x.value === '1')?.label,
         NO: crud?.options?.boolean?.find((x: any) => x.value === '0')?.label,
      };
   }, [crud?.options?.boolean]);

   const title = replaceKeyWithValue(
      crud?.pages?.view.title || 'View :model',
      'model',
      crud?.models?.company || 'Company'
   );

   const actions = getActions();

   return (
      <PageLayout title={StringHelpers.title(title)}>
         <WidthConstrainedContainer>
            {isLoading ? (
               <div className="d-flex justify-content-center align-items-center vh-100">
                  <Spinner />
               </div>
            ) : (
               <>
                  <div className="mb-4 d-flex justify-content-between align-items-center">
                     <Button
                        label={StringHelpers.title(crud?.buttons.back.default || 'Back')}
                        onClick={() => navigate('/companies')}
                        variant={Variants.Dark}
                        type={ButtonTypes.Outline}
                        disabled={disabled}
                     />
                     {actions.length > 0 && (
                        <Dropdown
                           label={StringHelpers.title(crud?.buttons?.actions?.default || 'Actions')}
                           items={getActions()}
                           type={ButtonTypes.Solid}
                           variant={Variants.Dark}
                           disabled={disabled}
                        />
                     )}
                  </div>
                  <Card>
                     <CardBody>
                        <SubTitle
                           title={StringHelpers.title(
                              `${crud?.models?.company} ${crud?.sub_titles?.details}`
                           )}
                           className="mt-4"
                           style={{ color: '#464C5E' }}
                        />
                        <div className="row">
                           <CardData.Column>
                              <CardData.ColumnItem
                                 title={StringHelpers.title(fields?.name || 'Name')}
                                 value={StringHelpers.title(companyData?.data?.name ?? '-')}
                              />
                              <CardData.ColumnItem
                                 title={StringHelpers.title(fields?.type || 'Type')}
                                 value={StringHelpers.title(companyData?.data?.type || '-')}
                              />
                              <CardData.ColumnItem
                                 title={StringHelpers.title(
                                    fields?.registration_state || 'Registration state'
                                 )}
                                 value={StringHelpers.title(
                                    companyData?.data?.registration_status || '-'
                                 )}
                              />
                           </CardData.Column>
                           <CardData.Column>
                              <CardData.ColumnItem
                                 title={StringHelpers.title(
                                    fields?.customer_email_enabled || 'Customer email enabled'
                                 )}
                                 value={
                                    companyData?.data?.customer_email_enabled
                                       ? booleanOptions.YES
                                       : booleanOptions.NO
                                 }
                              />
                              <CardData.ColumnItem
                                 title={StringHelpers.title(
                                    fields?.system_access_enabled || 'Customer email enabled'
                                 )}
                                 value={
                                    companyData?.data?.system_access_enabled
                                       ? booleanOptions.YES
                                       : booleanOptions.NO
                                 }
                              />
                              <CardData.ColumnItem
                                 title={StringHelpers.title(
                                    `${crud?.sub_titles?.primary} ${crud?.models?.user}`
                                 )}
                                 value={
                                    <>
                                       {companyData?.data?.primary_individual?.user?.permissions
                                          ?.view ? (
                                          <a
                                             href={`/user/${companyData?.data?.primary_individual?.user?.id}`}
                                             target="_blank"
                                             rel="noopener noreferrer"
                                          >
                                             {StringHelpers.title(
                                                companyData?.data?.primary_individual?.name || '-'
                                             )}
                                          </a>
                                       ) : (
                                          StringHelpers.title(
                                             companyData?.data?.primary_individual?.name || '-'
                                          )
                                       )}
                                    </>
                                 }
                              />
                           </CardData.Column>
                           {companyData?.data?.type === CompanyTypes.INSTALLER &&
                              themes.isToshiba && (
                                 <CardData.Column>
                                    <CardData.ColumnItem
                                       title={StringHelpers.title(
                                          fields?.distributors || 'Distributors'
                                       )}
                                       value={
                                          <>
                                             {companyData?.data?.distributors?.length
                                                ? companyData?.data?.distributors?.map(
                                                     (distributor) => (
                                                        <a
                                                           href={`/companies/${distributor}`}
                                                           key={distributor}
                                                           target="_blank"
                                                           rel="noopener noreferrer"
                                                        >
                                                           {distributor}
                                                        </a>
                                                     )
                                                  )
                                                : '-'}
                                          </>
                                       }
                                    />
                                    <CardData.ColumnItem
                                       title={StringHelpers.title(
                                          fields?.warranty_level || 'Warranty Level'
                                       )}
                                       value={companyData?.data?.toshiba_warranty_level || '-'}
                                    />
                                 </CardData.Column>
                              )}
                           <CardData.Column>
                              <CardData.ColumnItem
                                 title={StringHelpers.title(fields?.created_at || 'Created')}
                                 value={formatDateFns(
                                    companyData?.data?.created_at || '',
                                    DateFormats.DATETIME_SHORT
                                 )}
                              />
                              <CardData.ColumnItem
                                 title={StringHelpers.title(fields?.updated_at || 'Updated')}
                                 value={formatDateFns(
                                    companyData?.data?.updated_at || '',
                                    DateFormats.DATETIME_SHORT
                                 )}
                              />
                              {!!companyData?.data?.deleted_at && (
                                 <CardData.ColumnItem
                                    title={StringHelpers.title(fields?.archived_at || 'Archived')}
                                    value={formatDateFns(
                                       companyData?.data?.deleted_at,
                                       DateFormats.DATETIME_SHORT
                                    )}
                                 />
                              )}
                           </CardData.Column>
                        </div>
                        <SubTitle
                           className="mt-4"
                           style={{ color: '#464C5E' }}
                           title={StringHelpers.title(`${crud?.sub_titles?.address}`)}
                        />
                        <div className="row">
                           <CardData.Column>
                              <CardData.ColumnItem
                                 title={StringHelpers.title(`${fields?.address_line_1}`)}
                                 value={
                                    companyData?.data?.primary_site?.address?.address_line_1 || '-'
                                 }
                              />
                              <CardData.ColumnItem
                                 title={StringHelpers.title(`${fields?.address_line_2}`)}
                                 value={
                                    companyData?.data?.primary_site?.address?.address_line_2 || '-'
                                 }
                              />
                              <CardData.ColumnItem
                                 title={StringHelpers.title(`${fields?.address_line_3}`)}
                                 value={
                                    companyData?.data?.primary_site?.address?.address_line_3 || '-'
                                 }
                              />
                           </CardData.Column>
                           <CardData.Column>
                              <CardData.ColumnItem
                                 title={StringHelpers.title(`${fields?.town_city}`)}
                                 value={companyData?.data?.primary_site?.address?.town_city || '-'}
                              />
                              <CardData.ColumnItem
                                 title={StringHelpers.title(`${fields?.state_county}`)}
                                 value={
                                    companyData?.data?.primary_site?.address?.state_county || '-'
                                 }
                              />
                           </CardData.Column>
                           <CardData.Column>
                              <CardData.ColumnItem
                                 title={StringHelpers.title(`${fields?.postcode_zipcode}`)}
                                 value={
                                    companyData?.data?.primary_site?.address?.postcode_zipcode ||
                                    '-'
                                 }
                              />
                              <CardData.ColumnItem
                                 title={StringHelpers.title(`${fields?.country_code_iso_3}`)}
                                 value={companyData?.data?.primary_site?.address?.country || '-'}
                              />
                           </CardData.Column>
                        </div>
                        <>
                           <SubTitle
                              className="mt-4"
                              style={{ color: '#464C5E' }}
                              title={StringHelpers.title(
                                 `${crud?.sub_titles?.additional_platform} ${crud?.sub_titles?.details}`
                              )}
                           />
                           <div className="row">
                              {themes.isViessmann && (
                                 <>
                                    <CardData.Column>
                                       <CardData.ColumnItem
                                          title={StringHelpers.title(
                                             fields?.gassafe_number || 'Gas Safe number'
                                          )}
                                          value={companyData?.data?.gas_safe_number || '-'}
                                       />
                                       <CardData.ColumnItem
                                          title={StringHelpers.title(
                                             fields?.gassafe_confirmed_At || 'Gas Safe confirmed at'
                                          )}
                                          value={formatDateFns(
                                             companyData?.data?.gas_safe_confirmed_at || '-',
                                             DateFormats.DATETIME_SHORT
                                          )}
                                       />
                                    </CardData.Column>
                                    <CardData.Column>
                                       {themes.isViessmann && (
                                          <CardData.ColumnItem
                                             title={StringHelpers.title(
                                                fields?.sap_number || 'SAP number'
                                             )}
                                             value={companyData?.data?.sap_number || '-'}
                                          />
                                       )}
                                       {themes.isToshiba && (
                                          <>
                                             <CardData.ColumnItem
                                                title={StringHelpers.title(
                                                   fields?.fgas_refcom_standard_number ||
                                                      'FGAS/REFCOM standard number'
                                                )}
                                                value={
                                                   companyData?.data?.fgas_refcom_standard_number ||
                                                   '-'
                                                }
                                             />
                                             <CardData.ColumnItem
                                                title={StringHelpers.title(
                                                   fields?.refcom_elite_number ||
                                                      'FGAS/REFCOM elite number'
                                                )}
                                                value={
                                                   companyData?.data?.refcom_elite_number || '-'
                                                }
                                             />
                                          </>
                                       )}
                                    </CardData.Column>
                                    {themes.isViessmann && (
                                       <CardData.Column>
                                           <CardData.ColumnItem
                                               title={StringHelpers.title(
                                                   fields?.mcs_number || 'MCS number'
                                               )}
                                               value={companyData?.data?.mcs_number || '-'}
                                           />
                                       </CardData.Column>
                                    )}
                                 </>
                              )}
                              {themes.isToshiba && (
                                 <CardData.Column>
                                    <CardData.ColumnItem
                                       title={StringHelpers.title(
                                          fields?.fgas_refcom_approved_by ||
                                             'FGAS/REFCOM approved by'
                                       )}
                                       value={'-'}
                                    />
                                    <CardData.ColumnItem
                                       title={StringHelpers.title(
                                          fields?.fgas_refcom_approval_Date ||
                                             'FGAS/REFCOM approval date'
                                       )}
                                       value={formatDateFns(
                                          companyData?.data?.fgas_refcom_standard_confirmed_at ||
                                             '-'
                                       )}
                                    />
                                    <CardData.ColumnItem
                                       title={StringHelpers.title(
                                          fields?.refcom_elite_approved_by ||
                                             'REFCOM elite approved by'
                                       )}
                                       value={'-'}
                                    />
                                    <CardData.ColumnItem
                                       title={StringHelpers.title(
                                          fields?.refcom_elite_approval_Date ||
                                             'REFCOM elite approval date'
                                       )}
                                       value={formatDateFns(
                                          companyData?.data?.refcom_elite_confirmed_at || '-'
                                       )}
                                    />
                                 </CardData.Column>
                              )}
                           </div>
                        </>
                     </CardBody>
                  </Card>
                  <UsersList />
               </>
            )}
         </WidthConstrainedContainer>
         <Modal
            open={archiveModalOpen}
            onClose={() => setArchiveModalOpen(false)}
            title={StringHelpers.title(
               replaceKeyWithValue(
                  crud?.modals?.archive.title || 'Archive :name?',
                  'name',
                  crud?.models?.company || 'Company'
               )
            )}
            confirmText={
               archiving
                  ? crud?.buttons.archive.submitting || 'Archiving...'
                  : crud?.buttons.archive.default || 'Archive'
            }
            closeText={crud?.buttons.cancel.default || 'Cancel'}
            onConfirm={archive}
            disabled={disabled || archiving}
            variant={Variants.Danger}
         >
            {crud?.modals?.archive.description.map((item: string, key: number) => {
               return (
                  <p key={key}>
                     {replaceKeyWithValue(item, 'name', companyData?.data?.name || '-')}
                  </p>
               );
            })}
         </Modal>

         <Modal
            open={restoreModalOpen}
            onClose={() => setRestoreModalOpen(false)}
            title={StringHelpers.title(
               replaceKeyWithValue(
                  crud?.modals?.restore.title || 'Restore :name?',
                  'name',
                  crud?.models?.company || 'Company'
               )
            )}
            confirmText={
               archiving
                  ? crud?.buttons.restore.submitting || 'Restore...'
                  : crud?.buttons.restore.default || 'Restore'
            }
            closeText={crud?.buttons.cancel.default || 'Cancel'}
            onConfirm={restore}
            disabled={disabled || restoring}
            variant={Variants.Success}
         >
            {crud?.modals?.restore.description.map((item: string, key: number) => {
               return (
                  <p key={key}>
                     {replaceKeyWithValue(item, 'name', companyData?.data?.name || '-')}
                  </p>
               );
            })}
         </Modal>
      </PageLayout>
   );
}

export default Company;
