import axios from 'axios';
import {
	API_ENDPOINTS,
	Button,
	ButtonTypes,
	Card,
	CardBody,
	customToast,
	DataTable,
	Dropdown,
	formatDate,
	Icons,
	Modal,
	PageLayout,
	replaceKeyWithValue,
	route,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	useLangContext,
	useStore,
	Variants,
	WidthConstrainedContainer,
	DateFormats,
	useAuthorisationContext,
} from 'carrier-fe';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserViewType } from '../../../types/user';
import { DropdownItemProps } from 'carrier-fe/lib/components/Controls/Dropdown/DropdownItem/DropdownItem';
import { format, parse } from 'date-fns';
import TrainingCourses from './components/TrainingCourses';
import AccessLogs from './components/AccessLogs';

function User() {
	const { userId } = useParams();

	const { crud, fields } = useLangContext();
	const { disabled, setDisabled } = useDisabledContext();
	const { hasRoleAccess } = useAuthorisationContext();
	const navigate = useNavigate();

	const { store, setValues } = useStore<UserViewType>({} as UserViewType);

	const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);
	const [restoreModalOpen, setRestoreModalOpen] = useState<boolean>(false);
	const [archiving, setArchiving] = useState<boolean>(false);
	const [restoring, setRestoring] = useState<boolean>(false);

	const isSuperAdminOrDistributionManagerOrPlatformManager =
		hasRoleAccess('super_admin') ||
		hasRoleAccess('platform_manager') ||
		hasRoleAccess('distribution_manager');

	useEffect(() => {
		fetchCourseData();
	}, [userId]);

	useEffect(() => {
		if (archiving) {
			archive();
		}
	}, [archiving]);

	useEffect(() => {
		if (restoring) {
			restore();
		}
	}, [restoring]);

	const fetchCourseData = useCallback(async () => {
		try {
			const { data } = await axios.get<{ data: UserViewType }>(
				route(API_ENDPOINTS.ADMIN.USER.VIEW, {
					userId: String(userId),
				})
			);
			setValues(data.data);
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		}
	}, [userId]);

	const archive = async () => {
		setDisabled(true);

		try {
			let response = await axios.delete(
				route(API_ENDPOINTS.ADMIN.USER.DELETE, {
					userId: String(userId),
				})
			);
			customToast({
				title: response?.data?.message || 'Success.',
				variant: Variants.Success,
			});
			await fetchCourseData();
		} catch (error: any) {
			customToast({
				title:
					error?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			setDisabled(false);
			setArchiving(false);
			setArchiveModalOpen(false);
		}
	};

	const restore = async () => {
		setDisabled(true);

		try {
			let response = await axios.patch(
				route(API_ENDPOINTS.ADMIN.USER.RESTORE, {
					userId: String(userId),
				})
			);
			customToast({
				title: response?.data?.message || 'Success.',
				variant: Variants.Success,
			});
			await fetchCourseData();
		} catch (error: any) {
			customToast({
				title:
					error?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			setDisabled(false);
			setRestoring(false);
			setRestoreModalOpen(false);
		}
	};

	const getActions = (): DropdownItemProps[] => {
		if (!store || !store.permissions) return [];

		let actions: DropdownItemProps[] = [];

		if (store?.permissions.update) {
			actions.push({
				label: StringHelpers.title(
					crud?.buttons.edit.default || 'Update'
				),
				onClick: () => navigate(`/user/${userId}/edit`),
				variant: Variants.Dark,
				icon: Icons.EDIT,
				disabled: disabled,
			});
		}

		if (store?.permissions.delete) {
			actions.push({
				label: StringHelpers.title(
					crud?.buttons.archive.default || 'Archive'
				),
				onClick: () => setArchiveModalOpen(true),
				variant: Variants.Danger,
				icon: Icons.DELETE,
				disabled: disabled,
			});
		}

		if (store?.permissions.restore) {
			actions.push({
				label: StringHelpers.title(
					crud?.select_labels.restore || 'Restore'
				),
				onClick: () => setRestoreModalOpen(true),
				variant: Variants.Dark,
				icon: Icons.CHECKBOX_ON,
				disabled: disabled,
			});
		}

		if (!store?.deleted_at) {
			actions.push({
				label: StringHelpers.title(
					crud?.buttons.impersonate.default || 'Impersonate User'
				),
				onClick: () => alert('not implemented'),
				variant: Variants.Dark,
				icon: Icons.PROFILE,
				disabled: disabled,
			});
		}

		if (!store?.deleted_at && store?.system_access_enabled) {
			actions.push({
				label: StringHelpers.title(
					crud?.buttons.resend_email.default ||
						'Resend Onboarding Email'
				),
				onClick: () => alert('not implemented'),
				variant: Variants.Dark,
				icon: Icons.MAIL,
				disabled: disabled,
			});
		}

		return actions;
	};

	let title = replaceKeyWithValue(
		crud?.pages?.view.title || 'View :model',
		'model',
		crud?.models?.user || 'User'
	);

	let actions = getActions();

	return (
		<PageLayout title={StringHelpers.title(title)}>
			<WidthConstrainedContainer>
				<div className="mb-4 d-flex justify-content-between align-items-center">
					<Button
						label={StringHelpers.title(
							crud?.buttons?.back?.default || 'Back'
						)}
						onClick={() => navigate('/user')}
						variant={Variants.Dark}
						type={ButtonTypes.Outline}
						disabled={disabled}
					/>
					{actions.length > 0 && (
						<Dropdown
							label={StringHelpers.title(
								crud?.buttons?.actions?.default || 'Actions'
							)}
							items={getActions()}
							type={ButtonTypes.Solid}
							variant={Variants.Dark}
							disabled={disabled}
						/>
					)}
				</div>
				<Card className="flex-column-reverse flex-xl-row overflow-hidden rounded-4">
					<CardBody>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.details || 'Details'
							)}
							className="mb-3 w-100"
							style={{ color: '#464C5E' }}
						/>
						<div className="row row-cols-lg-3 mb-4">
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.first_name || 'First Name'
									)}
								</strong>
								<div>
									{StringHelpers.title(
										store?.individual?.first_name || '-'
									)}
								</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.last_name || 'Last Name'
									)}
								</strong>
								<div>{store?.individual?.last_name || '-'}</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.email || 'Email'
									)}
								</strong>
								{store?.email ? (
									<a href={'mailto:' + store.email}>
										{store.email}
									</a>
								) : (
									<div>-</div>
								)}
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.mobile || 'Mobile Number'
									)}
								</strong>
								{store?.individual?.display_mobile_number ? (
									<a
										href={
											'tel:' +
											store.individual
												.display_mobile_number
										}
									>
										{store.individual.display_mobile_number}
									</a>
								) : (
									<div>-</div>
								)}
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.landline || 'Landline Number'
									)}
								</strong>
								{store?.individual?.display_landline_number ? (
									<a
										href={
											'tel:' +
											store.individual
												.display_landline_number
										}
									>
										{
											store.individual
												.display_landline_number
										}
									</a>
								) : (
									<div>-</div>
								)}
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.created_at || 'created'
									)}
								</strong>
								<div>
									{store?.created_at
										? formatDate(store.created_at)
										: '-'}
								</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.updated_at || 'Updated'
									)}
								</strong>
								<div>
									{store?.updated_at
										? formatDate(store.updated_at)
										: '-'}
								</div>
							</div>
							{!!store?.deleted_at && (
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.archived_at || 'archived'
										)}
									</strong>
									<div>
										{store?.deleted_at
											? formatDate(store.deleted_at)
											: '-'}
									</div>
								</div>
							)}
						</div>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.address || 'Address'
							)}
							className="mb-3 w-100"
							style={{ color: '#464C5E' }}
						/>
						<div className="row row-cols-lg-3 mb-4">
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.address_line_1 ||
											'Address Line 1'
									)}
								</strong>
								<div>
									{store?.individual?.address?.address_line_1
										? store?.individual?.address
												?.address_line_1
										: '-'}
								</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.address_line_2 ||
											'Address Line 2'
									)}
								</strong>
								<div>
									{store?.individual?.address?.address_line_2
										? store?.individual?.address
												?.address_line_2
										: '-'}
								</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.address_line_3 ||
											'Address Line 3'
									)}
								</strong>
								<div>
									{store?.individual?.address?.address_line_3
										? store?.individual?.address
												?.address_line_3
										: '-'}
								</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.town_city || 'Town City'
									)}
								</strong>
								<div>
									{store?.individual?.address?.town_city
										? store?.individual?.address?.town_city
										: '-'}
								</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.state_county || 'State County'
									)}
								</strong>
								<div>
									{store?.individual?.address?.state_county
										? store?.individual?.address
												?.state_county
										: '-'}
								</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.postcode_zipcode ||
											'Postcode Zipcode'
									)}
								</strong>
								<div>
									{store?.individual?.address
										?.postcode_zipcode
										? store?.individual?.address
												?.postcode_zipcode
										: '-'}
								</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.country || 'Country'
									)}
								</strong>
								<div>
									{store?.individual?.address?.country
										? store?.individual?.address?.country
										: '-'}
								</div>
							</div>
						</div>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.additional_details ||
									'Additional Details'
							)}
							className="mb-3 w-100"
							style={{ color: '#464C5E' }}
						/>
						<div className="row row-cols-lg-3">
							{isSuperAdminOrDistributionManagerOrPlatformManager && (
								<>
									<div className="d-flex flex-column mb-3">
										<strong>
											{StringHelpers.title(
												fields?.company || 'Company'
											)}
										</strong>
										{store?.individual?.company?.name ? (
											<a
												href={
													'/company/' +
													store.individual.company_id
												}
											>
												{store.individual.company.name}
											</a>
										) : (
											<div>-</div>
										)}
									</div>
									<div className="d-flex flex-column mb-3">
										<strong>
											{StringHelpers.title(
												fields?.roles || 'Roles'
											)}
										</strong>
										<div>
											{store?.role_name_display
												? store.role_name_display
												: '-'}
										</div>
									</div>
								</>
							)}
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.system_access_enabled ||
											'System Access Enabled'
									)}
								</strong>
								<div>
									{store?.system_access_enabled === '1'
										? crud?.options.boolean[0].label ||
										  'Yes'
										: crud?.options.boolean[1].label ||
										  'No'}
								</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.gas_safe_licence ||
											'Gas Safe License Card Number'
									)}
								</strong>
								<div>
									{store?.gas_safe?.license
										? store?.gas_safe?.license
										: '-'}
								</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.gas_safe_licence_expiry ||
											'Gas Safe Licence Card Expiry Date'
									)}
								</strong>
								<div>
									{store?.gas_safe?.expiry_date
										? formatDate(
												store?.gas_safe?.expiry_date
										  )
										: '-'}
								</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.onboarding_completed ||
											'Onboarding Completed'
									)}
								</strong>
								<div>Y</div>
							</div>
						</div>
					</CardBody>
				</Card>
			</WidthConstrainedContainer>

			<Modal
				open={archiveModalOpen}
				onClose={() => setArchiveModalOpen(false)}
				title={StringHelpers.title(
					replaceKeyWithValue(
						crud?.modals?.archive.title || 'Archive :name?',
						'name',
						crud?.models?.user || 'User'
					)
				)}
				confirmText={
					archiving
						? crud?.buttons.archive.submitting || 'Archiving...'
						: crud?.buttons.archive.default || 'Archive'
				}
				closeText={crud?.buttons.cancel.default || 'Cancel'}
				onConfirm={() => setArchiving(true)}
				disabled={disabled || archiving}
				variant={Variants.Danger}
			>
				{!!store && (
					<>
						{crud?.modals?.archive.description.map(
							(item: string, key: number) => {
								return (
									<p key={key}>
										{replaceKeyWithValue(
											item,
											'name',
											store?.individual?.name || '-'
										)}
									</p>
								);
							}
						)}

						<div className="row row-cols-lg-2">
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.first_name || 'First Name'
									)}
								</strong>
								<div>
									{StringHelpers.title(
										store?.individual?.first_name || '-'
									)}
								</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.last_name || 'Last Name'
									)}
								</strong>
								<div>{store?.individual?.last_name || '-'}</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.email || 'Email'
									)}
								</strong>
								{store?.email ? (
									<div>{store.email}</div>
								) : (
									<div>-</div>
								)}
							</div>
							{isSuperAdminOrDistributionManagerOrPlatformManager && (
								<>
									<div className="d-flex flex-column mb-3">
										<strong>
											{StringHelpers.title(
												fields?.company || 'Company'
											)}
										</strong>
										{store?.individual?.company?.name ? (
											<div>
												{store.individual.company.name}
											</div>
										) : (
											<div>-</div>
										)}
									</div>
								</>
							)}
						</div>
					</>
				)}
			</Modal>

			<Modal
				open={restoreModalOpen}
				onClose={() => setRestoreModalOpen(false)}
				title={StringHelpers.title(
					replaceKeyWithValue(
						crud?.modals?.restore.title || 'Restore :name?',
						'name',
						crud?.models?.user || 'User'
					)
				)}
				confirmText={
					archiving
						? crud?.buttons.restore.submitting || 'Restore...'
						: crud?.buttons.restore.default || 'Restore'
				}
				closeText={crud?.buttons.cancel.default || 'Cancel'}
				onConfirm={() => setRestoring(true)}
				disabled={disabled || restoring}
				variant={Variants.Success}
			>
				{!!store && (
					<>
						{crud?.modals?.restore.description.map(
							(item: string, key: number) => {
								return (
									<p key={key}>
										{replaceKeyWithValue(
											item,
											'name',
											store?.individual?.name || '-'
										)}
									</p>
								);
							}
						)}
						<div className="row row-cols-lg-2">
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.first_name || 'First Name'
									)}
								</strong>
								<div>
									{StringHelpers.title(
										store?.individual?.first_name || '-'
									)}
								</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.last_name || 'Last Name'
									)}
								</strong>
								<div>{store?.individual?.last_name || '-'}</div>
							</div>
							<div className="d-flex flex-column mb-3">
								<strong>
									{StringHelpers.title(
										fields?.email || 'Email'
									)}
								</strong>
								{store?.email ? (
									<div>{store.email}</div>
								) : (
									<div>-</div>
								)}
							</div>
							{isSuperAdminOrDistributionManagerOrPlatformManager && (
								<>
									<div className="d-flex flex-column mb-3">
										<strong>
											{StringHelpers.title(
												fields?.company || 'Company'
											)}
										</strong>
										{store?.individual?.company?.name ? (
											<div>
												{store.individual.company.name}
											</div>
										) : (
											<div>'-'</div>
										)}
									</div>
								</>
							)}
						</div>
					</>
				)}
			</Modal>

			<TrainingCourses />
			<AccessLogs />
		</PageLayout>
	);
}

export default User;
