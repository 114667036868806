import { AxiosProvider, DisabledProvider, LangProvider, ThemeProvider, ToastProvider, HomeDashboardModalProvider } from 'carrier-fe';
import { ReactNode } from 'react';

interface ProviderProps {
  children: ReactNode;
}

function Providers(props: ProviderProps) {
  const { children } = props;

  return (
    <ThemeProvider>
      <AxiosProvider>
        <DisabledProvider>
          <LangProvider>
            <ToastProvider position={'top-right'}>
              <HomeDashboardModalProvider>
                {children}
              </HomeDashboardModalProvider>
            </ToastProvider>
          </LangProvider>
        </DisabledProvider>
      </AxiosProvider>
    </ThemeProvider>
  );
}

export default Providers;
