import axios from "axios";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { 
    API_ENDPOINTS, 
    BottomAnchoredControls, 
    Button, 
    ButtonTypes, 
    Card, 
    CardBody, 
    customToast,
    PageLayout, 
    replaceKeyWithValue, 
    route, 
    StringHelpers, 
    SubTitle, 
    useDisabledContext, 
    useForm, 
    useLangContext, 
    Variants, 
    WidthConstrainedContainer 
} from "carrier-fe";

const defaultData = {
	name: '',
	subject: '',
	platform_notification_text: '',
	email_top_text: '',
    email_button_text: '',
    email_bottom_text: '',
	email_bottom_text_enabled: false,
    platform_notification_text_enabled: false,
    email_top_text_enabled: false,
    email_button_text_enabled: false
};

type Data = typeof defaultData;

function EmailTemplate() {
    const { templateId } = useParams();
    const { fields, crud, models } = useLangContext();
    const { disabled } = useDisabledContext();
    const navigate = useNavigate();

    const patch = async (store: typeof defaultData) => {
        const res = await axios.patch(
            route(API_ENDPOINTS.ADMIN.NOTIFICATION_TEMPLATE.UPDATE, { templateId: templateId || "" }), store);

        return res;
    };

    const { Input, Submit, setValues, isSuccess, store } = useForm(patch, defaultData);
    
    const fetchTemplateData = useCallback(
        async () => {
            try {
                const { data } = await axios.get<{ data: Data }>(
                    route(
                        API_ENDPOINTS.ADMIN.NOTIFICATION_TEMPLATE.VIEW, {
                            templateId: String(templateId),
                        }
                    )
                );
                setValues(data.data)
            } catch (err: any) {
                customToast({
                    title: err?.response?.data?.message ||
                    "An error occurred, please try again",
                    variant: Variants.Danger,
                });  
            }
        }, [templateId]
    );

    useEffect(() => {
        fetchTemplateData();
    }, [templateId]);

    useEffect(() => {
        if (!isSuccess) return;
    
        navigate(
          "/email-templates"
        );
    }, [isSuccess])

    const title = useMemo(
		() =>
			StringHelpers.title(
				replaceKeyWithValue(
					crud?.pages?.edit.title || 'Edit :model',
					'model',
					crud?.models?.email_template || 'Email Template'
				)
			),
		[crud, models]
	);

    return (
        <PageLayout title={StringHelpers.title(title)}>
            <WidthConstrainedContainer>
                <Card> 
                    <CardBody>
                        <SubTitle
                            title={StringHelpers.title(crud?.sub_titles?.content || "Content")}
                            className="mb-3 w-100"
							style={{ color: '#464C5E' }}
                        />
                        <div>
                            {Input({
                                name: "name",
                                label: StringHelpers.title(fields?.name || "Name"),
                                disable: true, 
                            })}
                            {Input({
                                name: "subject",
                                label: StringHelpers.sentence(fields?.subject || "Subject") 
                            })}
                            { store?.platform_notification_text_enabled && (
                                Input({
                                    name: "platform_notification_text",
                                    label: StringHelpers.sentence(fields?.platform_notification_text || "Platform Notification Text"),
                                    type: "textarea"
                                })
                            )}
                            { store?.email_top_text_enabled && (
                                Input({
                                    name: "email_top_text",
                                    label: StringHelpers.sentence(fields?.email_top_text || "Top Text"),
                                    type: "textarea"
                                })
                            )}
                            { store?.email_button_text_enabled && (
                                Input({
                                    name: "email_button_text",
                                    label: StringHelpers.sentence(fields?.email_button_text || "Button Text")
                                })
                            )}
                            { store?.email_bottom_text_enabled && (
                                Input({
                                    name: "email_bottom_text",
                                    label: StringHelpers.sentence(fields?.email_bottom_text || "Bottom Text"),
                                    type: "textarea"
                                })
                            )}
                        </div>
                    </CardBody>
                </Card>
                
                <BottomAnchoredControls>
                    <Button
                        label={crud?.buttons.back.default || 'Back'}
                        variant={Variants.Dark}
                        type={ButtonTypes.Outline}
                        onClick={() => navigate("/email-templates")}
                        disabled={disabled}
                        className={'me-4'}
                    />
                    <Submit
                        defaultLabel={crud?.buttons.edit.default || "Update"}
                        loadingLabel={crud?.buttons.edit.submitting || "Updating..."}
                        disabled={disabled}
                        variant={Variants.Primary}
                    />
                </BottomAnchoredControls>
            </WidthConstrainedContainer>
        </PageLayout>
    )
};

export default EmailTemplate;