export enum CompanyTypes {
    INSTALLER       = 'installer',
    DISTRIBUTOR     = 'distributor',
}

export enum REG_STATUS {
    PENDING         = 'pending',
}

export enum SYSTEM_TYPES {
   GAS_BOILER = 'gas_boiler',
   HEAT_PUMPS = 'heat_pump',
   SOLAR = 'solar',
   OTHER = 'other',
}

