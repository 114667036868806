import {
   API_ENDPOINTS,
   DataTable,
   DateFormats,
   formatDate,
   formatDateFns,
   FormInput,
   getThemeFromUrl,
   Icon,
   Icons,
   Permissions,
   Roles,
   route,
   StringHelpers,
   Themes,
   useAuthorisationContext,
   useDisabledContext,
   useLangContext,
   useUserContext,
   Variants,
} from 'carrier-fe';
import { useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { UserType } from '../../../../types/user';

function UsersList() {
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();
   const { fields, crud, models } = useLangContext();
   const { hasPermissionAccess } = useAuthorisationContext();
   const { user } = useUserContext();
   const { disabled } = useDisabledContext();
   const companyId = useParams().companyId;

   const handleOptionSelect = (action: any, data: UserType) => {
      const { id = '' } = data;

      const actions: Record<string, () => void> = {
         view: () => navigate(`/user/${id}`),
      };

      if (typeof action === 'string' && actions[action]) {
         actions[action]();
      }
   };

   const items = useMemo(() => {
      const i = [];

      if (hasPermissionAccess(Permissions.ADMIN_USER_STORE)) {
         i.push({
            label: StringHelpers.title(crud.buttons.create.default || 'Create'),
            disabled: false,
            href: `/user/create?companyId=${companyId}`,
            icon: <Icon icon={Icons.ADD} />,
         });
      }

      return i;
   }, [crud, hasPermissionAccess, companyId]);

   const colMapping = (data: UserType) => {
      const cols: any = [
         {
            id: 'name',
            label: StringHelpers.title(fields?.name || 'Name'),
            value: StringHelpers.title(data.name || '-'),
            sortable: true,
         },
         {
            id: 'email',
            label: StringHelpers.title(fields?.email || 'Email'),
            value: StringHelpers.title(data.email || '-'),
            sortable: true,
         },
         {
            id: 'role_name',
            label: StringHelpers.title(fields?.roles || 'Role'),
            value: StringHelpers.title(data.role_name_display || '-'),
            sortable: true,
            visibleCondition: () =>
               [Roles.DISTRIBUTION_MANAGER, Roles.PLATFORM_MANAGER]?.includes(
                  user?.role_name || ('-' as any)
               ),
         },
         {
            id: 'created_at',
            label: StringHelpers.title(fields?.created || 'Created'),
            value: formatDateFns(data.created_at,DateFormats.DATETIME_SHORT),
            sortable: true,
         },
         {
            id: 'deleted_at',
            label: StringHelpers.title(fields?.archived || 'Archived'),
            value: formatDateFns(data.deleted_at),
            sortable: true,
            visibleCondition: () => !!searchParams.get('filter[trashed]'),
         },
         {
            id: 'actions',
            label: StringHelpers.title(fields?.actions || 'Actions'),
            value: data.permissions.view ? (
               <FormInput
                  type="select"
                  label={StringHelpers.title(fields?.actions || 'Action')}
                  placeholder={StringHelpers.title(fields?.select || 'Select')}
                  name="actions"
                  value=""
                  disabled={disabled}
                  onChange={(value) => {
                     if (!!value && typeof value === 'string') {
                        handleOptionSelect(value, data);
                     }
                  }}
                  options={[
                     {
                        label: StringHelpers.title(crud?.view || 'View'),
                        value: 'view',
                     },
                  ]}
                  noMargin
               />
            ) : null,
         },
      ];

      return cols;
   };

   return (
      <>
         <DataTable<UserType>
            title={`${crud?.models?.company} ${crud?.models?.users}`}
            routeEndpoint={route(API_ENDPOINTS.ADMIN.COMPANY.USERS, {
               companyId: companyId || '',
            })}
            colMapping={colMapping}
            items={items}
            incrementPaginationButtons
            canViewArchived
            variant={Variants.Info}
         />
      </>
   );
}

export default UsersList;
